import React, { useState } from 'react'
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material'
import { Instagram, LinkedIn, WhatsApp, Menu, Close } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'


const NavButton = (props) => {
    const navigate = useNavigate();

    return (
        <Box
            onClick={() => { navigate(props.path); if(props.onClick){props.onClick()} }}
            padding="13px 20px"
            position="relative"
            sx={{
                "&:hover": {
                    cursor: "pointer"
                },
                '&::after': {
                    content: "''",
                    position: "absolute",
                    width: "100%",
                    height: "0px",
                    left: 0,
                    bottom: 0,
                    opacity: 0,
                    background: "white",
                    transform: "scale(0,1)",
                    transition: "all 0.25s ease-in-out"
                },
                "&:hover::after": {
                    transform: "scale(1,1)",
                    height: "3px",
                    opacity: "1"
                }
            }}
        >
            <Typography
                fontFamily="Alata"
                lineHeight="1"
                sx={{
                    textWrap: "nowrap"
                }}
            >
                {props.children}
            </Typography>
        </Box>
    )
}

const SocialButton = (props) => {
    return (
        <Box width="50px" height="50px" display="flex" justifyContent="center" alignItems="center">
            <IconButton margin="0 12.5px" onClick={() => {window.open(props.link)}}>
                {props.children}
            </IconButton>
        </Box>
    )
}

const Navbar = () => {
    const medium = useMediaQuery('(max-width:986px)');
    const small = useMediaQuery('(max-width:687px)');
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Box
            display="flex"
            position="absolute"
            top="0"
            minHeight="50px"
            margin="20px 0px"
            width="100%"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            zIndex="10"
        >
            <Box
                width="100%"
                display="flex"
                justifyContent={medium ? "space-between" : "space-around"}
                alignItems="center"
                color="white"
                gap="20px"
                padding="0 50px"
            >
                <Box display="flex">
                    <SocialButton link="https://www.instagram.com/anchorexperiences/">
                        <Instagram sx={{
                            color: 'white',
                            fontSize: '26px'
                        }} />
                    </SocialButton>
                    <SocialButton link="https://www.linkedin.com/company/anchorexperiences/">
                        <LinkedIn sx={{
                            color: 'white',
                            fontSize: '26px'
                        }} />
                    </SocialButton>
                    <SocialButton link="https://api.whatsapp.com/send/?phone=5511991490485&text=Ol%C3%A1%2C+gostaria+de+mais+informa%C3%A7%C3%B5es+sobre+o+Evento%21&type=phone_number&app_absent=0">
                        <WhatsApp sx={{
                            color: 'white',
                            fontSize: '26px'
                        }} />
                    </SocialButton>
                </Box>
                {medium ? (
                    <>
                        <Box display="flex" flexDirection="column" justifyContent="end" alignItems="center">
                            <IconButton onClick={() => { setIsOpen(!isOpen) }}>
                                {isOpen ? (<Close sx={{
                                    color: "white",
                                    fontSize: '26px'
                                }} />) : (<Menu sx={{
                                    color: "white",
                                    fontSize: '26px'
                                }} />)}
                            </IconButton>
                        </Box>
                    </>) : (
                    <>
                        <Box display="flex" justifyContent="end" alignItems="center">
                            <NavButton path="/">HOME</NavButton>
                            <NavButton path="/eventos">EVENTOS</NavButton>
                            <NavButton path="/sobre ">SOBRE</NavButton>
                            <NavButton path="/quem-somos">QUEM SOMOS</NavButton>
                            <NavButton path="/fale-conosco">FALE CONOSCO</NavButton>
                        </Box>
                    </>)}
            </Box>
            {isOpen &&
                <Box
                    display="flex"
                    width="100%"
                    padding="0 20px"
                    flexDirection="column"
                    color="white"
                    backgroundColor="rgba(0,0,0,0.4)"

                >
                    <NavButton path="/" onClick={() => { setIsOpen(false); }}>HOME</NavButton>
                    <NavButton path="/eventos" onClick={() => { setIsOpen(false) }}>EVENTOS</NavButton>
                    <NavButton path="/sobre" onClick={() => { setIsOpen(false) }}>SOBRE</NavButton>
                    <NavButton path="/quem-somos" onClick={() => { setIsOpen(false) }}>QUEM SOMOS</NavButton>
                    <NavButton path="/fale-conosco" onClick={() => { setIsOpen(false) }}>FALE CONOSCO</NavButton>
                </Box>
            }
        </Box>
    )
}

export default Navbar