import React from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import BackgroundBlur from "../../assets/Background-Blur.jpg"
import QuemSomosLogo from "../../assets/QuemSomos.png"
import DarkLogo from '../../assets/DarkLogo.png'


const QuemSomos = () => {
    const small = useMediaQuery("(max-width:968px)")
    return (
        <Box width="100%" display="flex" flexDirection="column" alignItems="center">
            <Box
                width="100%"
                height="90px"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                    background: `linear-gradient(to right, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${BackgroundBlur})`,
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed",
                }}
            >
            </Box>
            <Box width="clamp(0px, 100%, 1300px)" margin="80px 0" display="flex" flexDirection="column" gap="40px">
                <Box display="flex" justifyContent="center" alignItems="center">
                    <img src={QuemSomosLogo} />
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    padding="0 80px"
                    flexDirection={small ? "column" : "none"}
                    gap="30px"
                >
                    {!small &&
                        <Box
                            flex={2.5}
                            display="flex"
                            justifyContent="center"
                            overflow="hidden"
                        >
                            <img width="70%" minWidth="200px" src={DarkLogo} />
                        </Box>
                    }
                    <Box display="flex" flex={3} justifyContent="center">
                        <div id="content" class="site-content">
                            <div class="ast-container">
                                <div id="primary" class="content-area primary">
                                    <article id="post-3357" class="post-3357 page type-page status-publish ast-article-single">
                                        <div class="entry-content clear">
                                            <div class="elementor elementor-3357" data-elementor-type="wp-page" data-elementor-id="3357">
                                                <div class="elementor-element elementor-element-9bda359 e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="9bda359" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                    <div class="e-con-inner">
                                                        <div class="elementor-element elementor-element-fc99db8 e-con-full e-flex e-con e-parent e-lazyloaded" data-id="fc99db8" data-element_type="container">
                                                            <div class="elementor-element elementor-element-db55252 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile_extra__width-initial elementor-widget elementor-widget-text-editor" data-id="db55252" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>Somos a&nbsp;<strong>Anchor Experiences</strong>, produtora de eventos pr&oacute;prios, culturais e musicais (feiras, festivais, m&uacute;sica e afins).</p>
                                                                    <p>Usamos nossa &acirc;ncora para &ldquo;prender&rdquo; nosso p&uacute;blico, no bom sentido. Nos dedicamos a promover momentos &uacute;nicos e inesquec&iacute;veis em todas as &eacute;pocas do ano, para p&uacute;blicos de todas as idades, buscando sempre a&nbsp;<strong>inova&ccedil;&atilde;o</strong>. Inova&ccedil;&atilde;o para novas experi&ecirc;ncias que se tornar&atilde;o as melhores lembran&ccedil;as.</p>
                                                                    <p>Fa&ccedil;a com que os grandes momentos se repitam.</p>
                                                                    <p>Cada um de n&oacute;s &eacute; a soma de todas as nossas lembran&ccedil;as e experi&ecirc;ncias. E, por esse motivo, o maior prop&oacute;sito da Anchor &eacute; e sempre ser&aacute; o mesmo: proporcionar as melhores experi&ecirc;ncias.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                        <div class="elementor elementor-2161 elementor-location-footer" data-elementor-type="footer" data-elementor-id="2161">&nbsp;</div>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default QuemSomos