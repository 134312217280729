import React, { useState } from 'react'

import { Box, Typography, styled } from '@mui/material'
import PianoEventoImagem from '../../assets/PianoEventoImagem.jpg'
import BackgroundBlur from "../../assets/Background-Blur.jpg"
import { Today } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import TicketBuyer from './TicketBuyer'


const Evento = () => {

    const FirstLevelBox = styled(Box)(() => ({
        display: "flex",
        width: "100%",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
    }));

    const Navbar = styled(Box)(() => ({
        width: "100%",
        height: "90px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: `linear-gradient(to right, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${BackgroundBlur})`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",

    }));

    const EventBannerContainer = styled(Box)(() => ({
        width: "100%",
        height: "454px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: `linear-gradient(to right, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${BackgroundBlur})`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        position: "absolute",
        top: 0

    }));

    const EventBanner = styled(Box)(() => ({
        width: "clamp(0px, 100%, 1680px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "end",
        gap: "30px",
        padding: "30px",
    }));

    return (
        <FirstLevelBox>
            <Navbar />
            <EventBannerContainer>
            </EventBannerContainer>
            <Box
                display="flex"
                width="clamp(0px, 100%, 1680px)"
                padding="50px"
                gap="90px"
                justifyContent="center"
            >
                <Box display="flex" flexDirection="column" flex={1.8} gap="40px">
                    <Box
                        display="flex"
                        height="400px"
                        maxWidth="1100px"
                        overflow="hidden"
                        borderRadius="30px"
                    >
                        <img width="100%" style={{ objectFit: "cover", scale: "1" }} src={PianoEventoImagem} />

                    </Box>
                    <Box flex={1} fontFamily="Roboto" color="#1e293b">
                        <Typography fontFamily="Montserrat" fontSize="40px" color="#1e293b" fontWeight="800">
                            Once Upon A Time: Classical Piano Music
                        </Typography>
                        <div
                            class="elementor-element elementor-element-327228a e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="327228a" data-element_type="container">
                            <div class="e-con-inner">
                                <div class="elementor-element elementor-element-12c12c0 elementor-widget elementor-widget-heading" data-id="12c12c0" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">Descri&ccedil;&atilde;o do evento</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-7929182 e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="7929182" data-element_type="container">
                            <div class="e-con-inner">
                                <div class="elementor-element elementor-element-bed8a72 elementor-widget elementor-widget-text-editor" data-id="bed8a72" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p>Nostalgia de tempos em que n&atilde;o vivemos.</p>
                                        <p>&ldquo;<em>Once&nbsp;Upon A Time: Classical Piano Music</em>&rdquo; nos traz o melhor da m&uacute;sica cl&aacute;ssica em pe&ccedil;as para piano solo.</p>
                                        <p>Uma verdadeira imers&atilde;o nesse universo com as obras mais populares do piano, proporcionando uma experi&ecirc;ncia &uacute;nica para aqueles apaixonados por m&uacute;sica. S&atilde;o momentos intensos de interpreta&ccedil;&otilde;es marcantes, que v&atilde;o emocionar e tocar seu cora&ccedil;&atilde;o.</p>
                                        <p>Venha se encantar com a beleza da m&uacute;sica erudita e se emocionar com a pureza do piano.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-2d3d342 e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="2d3d342" data-element_type="container">
                            <div class="e-con-inner">
                                <div class="elementor-element elementor-element-b33bb14 elementor-widget elementor-widget-heading" data-id="b33bb14" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">Informa&ccedil;&otilde;es</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-214743b e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="214743b" data-element_type="container">
                            <div class="e-con-inner">
                                <div class="elementor-element elementor-element-296d5a4 elementor-widget elementor-widget-text-editor" data-id="296d5a4" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p data-key="98"><span data-key="97">📅<strong>&nbsp;Data e hora:</strong>&nbsp;A definir<br /></span><span data-key="101">📍&nbsp;<strong>Local:</strong>&nbsp;A definir<br /></span>⏳&nbsp;<strong>Dura&ccedil;&atilde;o:</strong>&nbsp;aprox. 60 minutos<br />👤<strong>&nbsp;Idade:</strong>&nbsp;a partir dos 8 anos (menores de 16 anos dever&atilde;o ser acompanhados por um adulto)<br /><span data-key="105">♿&nbsp;<strong>Acessibilidade:</strong>&nbsp;A definir<br /></span>💺&nbsp;<strong>Assentos:</strong>&nbsp;A definir</p>
                                        <p data-key="98"><span data-key="113">Consulte todos os detalhes sobre a meia-entrada&nbsp;<a href="http://anchorexperiences.com.br/politicas-de-meia-entrada/" target="_blank" rel="noopener">aqui</a></span></p>
                                        <p data-key="98"><span data-key="113">Consulte as perguntas frequentes (FAQ) sobre este evento&nbsp;<a href="https://anchorexperiences.com.br/once-upon-a-time-classical-piano-music-faq/" target="_blank" rel="noopener">aqui</a>.</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-f8b3ca1 e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="f8b3ca1" data-element_type="container">
                            <div class="e-con-inner">
                                <div class="elementor-element elementor-element-92c3520 elementor-widget elementor-widget-heading" data-id="92c3520" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">Programa&ccedil;&atilde;o prevista</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-8c27b0a e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="8c27b0a" data-element_type="container">
                            <div class="e-con-inner">
                                <div class="elementor-element elementor-element-99d9c8b elementor-widget elementor-widget-text-editor" data-id="99d9c8b" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p class="LC20lb MBeuO DKV0Md">🎵&nbsp;<strong>J.S. Bach&nbsp;</strong><em>(pe&ccedil;as a definir)</em><br />🎵&nbsp;<strong>W.A. Mozart</strong>&nbsp;<em>(pe&ccedil;as a definir)</em><br />🎵&nbsp;<strong>L.V. Beethoven</strong>&nbsp;<em>(pe&ccedil;as a definir)</em><br />🎵&nbsp;<strong>F. Chopin</strong>&nbsp;<em>(pe&ccedil;as a definir)</em><br />🎵&nbsp;<strong>F. Liszt</strong>&nbsp;<em>(pe&ccedil;as a definir)</em><br />🎵&nbsp;<strong>C. Debussy</strong>&nbsp;<em>(pe&ccedil;as a definir)</em><br />🎵&nbsp;<strong>E. Satie</strong>&nbsp;<em>(pe&ccedil;as a definir)</em></p>
                                        <p data-key="21"><em>(por motivos diversos, a programa&ccedil;&atilde;o pode sofrer altera&ccedil;&otilde;es)</em></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-3cb0d28 e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="3cb0d28" data-element_type="container">
                            <div class="e-con-inner">
                                <div class="elementor-element elementor-element-a702709 elementor-widget elementor-widget-heading" data-id="a702709" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">Artista</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-524581c e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="524581c" data-element_type="container">
                            <div class="e-con-inner"><footer class="elementor-element elementor-element-c8b429b e-con-full e-flex e-con e-child" data-id="c8b429b" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                <div class="elementor-element elementor-element-314dad5 e-con-full e-flex e-con e-child" data-id="314dad5" data-element_type="container">&nbsp;</div>
                                <div class="elementor-element elementor-element-42f1988 e-con-full e-flex e-con e-child" data-id="42f1988" data-element_type="container">
                                    <div class="elementor-element elementor-element-1b53861 elementor-widget elementor-widget-heading" data-id="1b53861" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <h4 class="elementor-heading-title elementor-size-default">Carlos Vogt &mdash; pianista</h4>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-5350ee1 elementor-widget elementor-widget-heading" data-id="5350ee1" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <h4 class="elementor-heading-title elementor-size-default"><a href="https://www.instagram.com/CarlosVogtPianista" target="_blank">@CarlosVogtPianista</a></h4>
                                        </div>
                                    </div>
                                </div>
                            </footer></div>
                        </div>
                        <div class="elementor-element elementor-element-7024bda e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="7024bda" data-element_type="container">
                            <div class="e-con-inner">
                                <div class="elementor-element elementor-element-4ef6c9a elementor-widget elementor-widget-heading" data-id="4ef6c9a" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">B&ocirc;nus</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-cbeba8a e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="cbeba8a" data-element_type="container">
                            <div class="e-con-inner">
                                <div class="elementor-element elementor-element-32cb146 elementor-widget elementor-widget-text-editor" data-id="32cb146" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p data-key="98"><strong>Exposi&ccedil;&atilde;o virtual&nbsp;</strong></p>
                                        <p data-key="98">🎹 Al&eacute;m do espet&aacute;culo, ser&aacute; proporcionada uma jornada fascinante pelos bastidores dos maiores mestres da composi&ccedil;&atilde;o.&nbsp;</p>
                                        <p data-key="98"><strong>📱&nbsp;</strong>Uma exposi&ccedil;&atilde;o virtual, onde somos transportados para &eacute;pocas remotas, no mundo desses maiores compositores cl&aacute;ssicos, com imagens aut&ecirc;nticas, representa&ccedil;&otilde;es em 3D, biografias e fatos sobre suas vidas, que se unem para revelar os segredos e inspira&ccedil;&otilde;es por tr&aacute;s de suas composi&ccedil;&otilde;es atemporais.&nbsp;</p>
                                        <p data-key="98">🎼&nbsp;Atrav&eacute;s do contexto dos compositores, nos sentiremos mais pr&oacute;ximos desses g&ecirc;nios musicais do passado e apreciaremos de forma ainda mais sublime suas composi&ccedil;&otilde;es.</p>
                                        <p data-key="98">🎁&nbsp;Prepare-se para uma experi&ecirc;ncia inovadora e enriquecedora, que ser&aacute; o b&ocirc;nus especial do evento &ldquo;<em>Once Upon A Time: Classical Piano Music</em>&ldquo;.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-24cac3b e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="24cac3b" data-element_type="container">
                            <div class="e-con-inner">
                                <div class="elementor-element elementor-element-e91e254 elementor-widget elementor-widget-heading" data-id="e91e254" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">Local</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-95080a6 e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="95080a6" data-element_type="container">
                            <div class="e-con-inner">
                                <div class="elementor-element elementor-element-7f85a2a elementor-widget elementor-widget-text-editor" data-id="7f85a2a" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p data-key="21">A definir</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-c06cd12 e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="c06cd12" data-element_type="container">
                            <div class="e-con-inner">
                                <div class="elementor-element elementor-element-653c0ee elementor-widget elementor-widget-heading" data-id="653c0ee" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">Galeria - edi&ccedil;&otilde;es anteriores</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
                <Box flex={1} display="flex" alignItems="start" position="relative" top="110px">
                    <TicketBuyer />
                </Box>
            </Box>
        </FirstLevelBox>
    )
}

export default Evento