import React from 'react'
import { Box, Divider, Radio, FormControlLabel, RadioGroup, Typography, SvgIcon } from '@mui/material'
import { LocationOn, Today, Chair, LocalActivity, Info, Lock, CreditCard } from '@mui/icons-material'
import {ReactComponent as Pix} from '../../assets/pix.svg'
import {ReactComponent as Boleto} from '../../assets/Boleto.svg'
import {ReactComponent as GPay} from '../../assets/Gpay.svg'


const Payment = () => {
  return (
    <Box width="100%">
      <Box
        width="clamp(0px, 100%, 1680px)"
        padding="20px"
        display="flex"
        flexDirection="column"
        gap="20px"
        fontFamily="Alata"
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="baseline"
          gap="10px"
        >
          <Box display="flex" gap="10px" alignItems="center">
            <LocationOn />
            <Typography>
              Localização
            </Typography>
          </Box>
          <Box display="flex" gap="10px" alignItems="center">
            <Today />
            <Typography>
              Data
            </Typography>
          </Box>
          <Box display="flex" gap="10px" alignItems="center">
            <Chair />
            <Typography>
              Assento
            </Typography>
          </Box>
          <Box display="flex" gap="10px" alignItems="center">
            <LocalActivity />
            <Typography>
              1x Ingressos - 210,00R$
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          display="flex"
          flexDirection="column"
          gap="20px"
        >
          <Box display="flex" justifyContent="space-between">
            <Typography>Total:</Typography>
            <Typography>210,00R$</Typography>
          </Box>
          <Box
            display="flex"
            gap="10px"
            backgroundColor="#f0ebfd"
            alignItems="center"
            padding="10px 20px"
          >
            <Info sx={{ fontSize: "15px" }} />
            <Typography>Sem taxas de conveniência</Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          display="flex"
          flexDirection="column"
          gap="10px"

        >
          <Typography>Formas de pagamento</Typography>
          <Box
            display="flex"
            flexDirection="column"
            gap="20px"
          >
            <RadioGroup
              defaultValue="creditCard"
              value="creditCard"
              sx={{
                flexDirection: "column",
                display: "flex",
                gap: "10px"
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                gap="10px"
                height="50px"
              >
                <Radio
                  value="creditCard"
                />
                <CreditCard />
                <Typography>Cartão de crédito</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px">
                <Radio
                  value="googlePay"
                />
                <GPay width="25px"/>
                <Typography>Google Pay</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px">
                <Radio
                  value="pix"
                />
                  <Pix width="20px"/>
                <Typography>Pix</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px">
                <Radio
                  value="boleto"
                />
                <Boleto height="20px" width="20px"/>
                <Typography>Boleto</Typography>
              </Box>
            </RadioGroup>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                gap: "10px"
              }}
            >
              <Lock sx={{ fontSize: "15px" }} />
              <Typography>
                Suas informações de pagamento são armazenadas com segurança
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Payment