import { Box, Button, IconButton, MenuItem, styled, TextField, Typography, Select, useMediaQuery } from '@mui/material'
import axios from 'axios';
import { Formik } from 'formik'
import { IMaskInput } from 'react-imask';
import schema from './Schema'
import React from 'react'

import BackgroundBlur from "../../assets/Background-Blur.jpg"
import WhatsappFaleConosco from "../../assets/WhatsappFaleConosco.png"
import EmailFaleConosco from "../../assets/EmailFaleConosco.png"
import InstagramFaleConosco from "../../assets/InstagramFaleConosco.png"
import LinkedinFaleConosco from "../../assets/LinkedinFaleConosco.png"

import { KeyboardArrowDown } from '@mui/icons-material'

const initialValues = {
  name: "",
  email: "",
  phone: "",
  event: "Evento",
  subject: "Assunto",
  message: "",
}

const FormattedPhoneInput = (props) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(00) 0000-00000"
      unmask={true}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  )
}

const FaleConosco = () => {
  const small = useMediaQuery("(max-width:768px");

  const FirstLevelBox = styled(Box)(() => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }));

  const BannerContainer = styled(Box)(() => ({
    width: "100%",
    paddingTop: "140px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: `linear-gradient(to right, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${BackgroundBlur})`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",

  }));

  const Banner = styled(Box)(() => ({
    width: "clamp(0px, 100%, 1680px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: "50px",
    padding: "30px"
  }));

  const ContactBanner = styled(Box)(() => ({
    minWidth: small ? "70%" : "220px",
    flex: "1",
    flexGrow: "0",
    flexShrink: "0",
    flexBasis: small ? "40%" : "20%",
    transition: "all ease 0.75s",
    "&:hover": {
      scale: 1.2,
      cursor: "pointer"
    }
  }));

  const handleFormSubmit = async (values, actions) => {
    try {
      const response = await axios.post('faleconosco.php', {...values}, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } catch (error) {
    }
  }

  return (
    <FirstLevelBox>
      <BannerContainer>
        <Banner>
          <Typography
            sx={{
              fontFamily: "Alata",
              fontSize: "35px",
              letterSpacing: "4px",
              fontWeight: "600",
              color: "white"
            }}
          >
            FALE CONOSCO
          </Typography>
          <Typography
            sx={{
              fontFamily: "Economica",
              fontSize: "32px",
              letterSpacing: "2px",
              fontWeight: "400",
              color: "#FFFECF",
              textAlign: "center"
            }}
          >
            Como você gostaria de falar com a gente?
          </Typography>
          <Box
            display="flex"
            flexWrap="wrap"
            gap="30px"
            justifyContent="center"
            width="100%"
          >
            <ContactBanner onClick={() => { window.open("https://api.whatsapp.com/send/?phone=5511991490485&text=Ol%C3%A1%2C+gostaria+de+mais+informa%C3%A7%C3%B5es+sobre+o+Evento%21&type=phone_number&app_absent=0") }}>
              <img width="100%" src={WhatsappFaleConosco} />
            </ContactBanner>
            <ContactBanner onClick={() => (window.open("https://www.instagram.com/anchorexperiences/"))}>
              <img width="100%" src={InstagramFaleConosco} />
            </ContactBanner>
            <ContactBanner onClick={() => (window.open("https://www.linkedin.com/company/anchorexperiences/"))}>
              <img width="100%" src={LinkedinFaleConosco} />
            </ContactBanner>
          </Box>
          <Typography
            sx={{
              fontFamily: "Economica",
              fontSize: "32px",
              letterSpacing: "2px",
              fontWeight: "400",
              color: "#FFFECF"
            }}
            textAlign="center"
          >
            Ou, se preferir, preencha o formulário abaixo
          </Typography>
          <IconButton>
            <KeyboardArrowDown
              sx={{
                color: "white",
                fontSize: "45px"
              }}
            />
          </IconButton>
        </Banner>
      </BannerContainer>
      <Banner>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={schema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue
          }) => (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                width: "clamp(0px, 100%, 1200px)",
                gap: "10px 30px",
                padding: small ? "20px" : "50px"
              }}
              component={'form'}
              onSubmit={handleSubmit}
            >
              <TextField
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={touched.name && errors.name}
                margin="dense"
                helperText={touched.name && errors.name ? errors.name : ' '}
                name="name"
                placeholder="Nome"
                type="text"
                sx={{
                  gridColumn: "span 4"
                }}
              />
              <TextField
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={touched.email && errors.email}
                helperText={touched.email && errors.email ? errors.email : ' '}
                name="email"
                placeholder="Email"
                type="text"
                sx={{
                  gridColumn: small ? "span 4" : "span 2"
                }}
              />
              <TextField
                placeholder="Telefone/Whatsapp"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                error={touched.phone && errors.phone}
                helperText={touched.phone && errors.phone ? errors.phone : ' '}
                slotProps={{
                  input: {
                    inputComponent: FormattedPhoneInput,
                  },
                }}
                name="phone"
                type="text"
                sx={{
                  gridColumn: small ? "span 4" : "span 2"
                }}
              />
              <TextField
                name="event"
                value={values.event}
                error={touched.event && errors.event}
                helperText={touched.event && errors.event ? errors.event : ' '}
                onChange={handleChange}
                onBlur={handleBlur}
                select
                sx={{
                  gridColumn: small ? "span 4" : "span 2"
                }}
              >
                <MenuItem disabled value="Evento">
                  Evento
                </MenuItem>
                <MenuItem value="Once Upon a Time">Once Upon a Time</MenuItem>
                <MenuItem value="Não relacionado a evento">Não relacionado a evento</MenuItem>
              </TextField>
              <TextField
                name="subject"
                value={values.subject}
                error={touched.subject && errors.subject}
                helperText={touched.subject && errors.subject ? errors.subject : ' '}
                onChange={handleChange}
                onBlur={handleBlur}
                select
                sx={{
                  gridColumn: small ? "span 4" : "span 2"
                }}
              >
                <MenuItem disabled value="Assunto">
                  Assunto
                </MenuItem>
                <MenuItem value="Reembolso">Reembolso</MenuItem>
                <MenuItem value="Outro">Outro</MenuItem>
              </TextField>
              <TextField
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                error={touched.message && errors.message}
                helperText={touched.message && errors.message ? errors.message : ' '}
                name="message"
                placeholder="Escreva sua mensagem..."
                multiline
                type="text"
                rows="10"
                maxRows="10"
                sx={{
                  gridColumn: "span 4"
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gridColumn: "span 4"
                }}
              >
                <Button
                  type="submit"
                  sx={{
                    fontSize: "26px"
                  }}
                >
                  <Typography>
                    Enviar Mensagem
                  </Typography>
                </Button>
              </Box>
            </Box>
          )}
        </Formik>
      </Banner>
    </FirstLevelBox>
  )
}

export default FaleConosco