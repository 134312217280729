import './App.css';
import Home from './Scenes/Home/Home';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Box, ThemeProvider, createTheme } from '@mui/material';

import Navbar from './Scenes/Global/Navbar';
import Footer from "./Scenes/Global/Footer";
import Evento from './Scenes/Ingresso/Evento';
import Eventos from "./Scenes/Eventos/Eventos"
import Sobre from './Scenes/Sobre/Sobre';
import QuemSomos from './Scenes/QuemSomos/QuemSomos';
import FaleConosco from './Scenes/FaleConosco/FaleConosco';
import Checkout from './Scenes/Checkout/Checkout';
import { useEffect } from 'react';
import MeiaEntrada from './Scenes/Politicas/MeiaEntrada';
import Privacidade from './Scenes/Politicas/Privacidade';
import Reembolso from './Scenes/Politicas/Reembolso'
import TermosECondicoes from './Scenes/Politicas/TermosECondicoes';


function App() {
  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: "16px",
            fontFamily: "Arial",
            backgroundColor: "#4742F1",
            textTransform: "none",
            borderRadius: "10px",
            color:"white",
            fontWeight: "700",
            padding: "5px 25px",
            textWrap: "nowrap"
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: "inherit",
            fontSize: "inherit",
            fontWeight: "inherit",
            textWrap: "inherit",
            lineHeight: "inherit",
            letterSpacing: "inherit"
          }
        }
      }
    }
  });

  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0,0);
  }, [pathname])

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/eventos" element={<Eventos />} />
          <Route path="/sobre" element={<Sobre />} />
          <Route path="/quem-somos" element={<QuemSomos />} />
          <Route path="/politicas-de-meia-entrada" element={<MeiaEntrada/>}/>
          <Route path="/politicas-de-privacidade" element={<Privacidade/>}/>
          <Route path="/termos-e-condicoes" element={<TermosECondicoes/>}/>
          <Route path="/reembolso" element={<Reembolso/>}/>
          <Route path="/fale-conosco" element={<FaleConosco />} />
          <Route path="/comprar-ingressos" element={<Evento />} />
          <Route path="/checkout" element={<Checkout />} />
        </Routes>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
