import { Box, Typography, styled } from '@mui/material'
import React from 'react'
import BackgroundBlur from "../../assets/Background-Blur.jpg"
import OnceUponATime from "../../assets/OnceUponATime.jpg"
import AnimeBrasilClub from '../../assets/AnimeBrazilClub.jpg'
import PartyClub from '../../assets/PartyClub.jpg'


const Eventos = () => {
    const BannerContainer = styled(Box)(() => ({
        width: "100%",
        padding: "180px 0",
        minHeight: "640px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: `linear-gradient(to right, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${BackgroundBlur})`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",

    }));

    const Banner = styled(Box)(() => ({
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: "50px",
        animation: "0.75s linear fadeIn",
        "@keyframes fadeIn": {
            "0%": {
                opacity: 0
            },
            "100%": {
                opacity: 1
            }
        }
    }))

    const Title = styled(Box)(() => ({
        color: "white",
        fontFamily: "Alata",
        fontSize: "40px",
        letterSpacing: "4px",
        fontWeight: "600"
    }))

    const CardGroup = styled(Box)(() => ({
        display: "flex",
        gap: "60px",
        flexWrap: "wrap",
        justifyContent: "center",
    }))

    const Card = styled(Box)(() => ({
        width: "200px",
        height: "200px",
        overflow: "hidden",
        borderRadius: "5px",
        transition: "all ease 0.25s",
        "&:hover": {
            transform: "scale(1.2)",
            cursor: "pointer"
        }

    }))

    return (
        <BannerContainer>
            <Banner>
                <Title>
                    <Typography>
                        EVENTOS
                    </Typography>
                </Title>
                <CardGroup>
                    <Card>
                        <img width="100%" src={OnceUponATime} />
                    </Card>
                    <Card>
                        <img width="100%" src={AnimeBrasilClub} />
                    </Card>
                    <Card>
                        <img width="100%" src={PartyClub} />
                    </Card>
                </CardGroup>
            </Banner>
        </BannerContainer>
    )
}

export default Eventos