import { Box, Divider, Typography, useMediaQuery } from '@mui/material'
import React from 'react'

import SobreAnchor from "../../assets/SobreAnchor.png"
import SobreAncora from "../../assets/SobreAncora.png"
import SobreA from "../../assets/SobreA.png"
import SobreCruz from "../../assets/SobreCruz.png"

const Sobre = () => {
    const small = useMediaQuery("(min-width:876px)")

    return (
        <Box
            width="100%"
            backgroundColor="#000036"
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding="100px 0"
        >
            <Box
                width="clamp(0px, 100%, 1680px)"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                padding="30px"
                gap="30px"
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap="30px"
                >
                    <Box height="125px" overflow="hidden">
                        <img height="100%" src={SobreAnchor} />
                    </Box>
                    <Box textAlign="center">
                        <Typography
                            color="white"
                            fontFamily="Arial"
                            fontSize="19px"
                            fontWeight="400"
                        >
                            Cada um de nós é a soma de todas as nossas lembranças e experiências. E, por esse motivo, o maior propósito
                            da Anchor Experiences é e sempre será o mesmo: proporcionar as melhores experiências.
                        </Typography>
                    </Box>
                </Box>
                <Divider
                    sx={{
                        backgroundColor: "white",
                        height: "2px",
                        width: "55%"
                    }}
                />
                <Box display="flex" flexDirection="column" gap="50px" justifyContent="center" alignItems="center" textAlign="center">
                    <Box>
                        <Typography
                            color="#FFFCB6"
                            fontFamily="Alata"
                            fontSize="28px"
                            fontWeight="400"
                        >
                            Significado da logo "ANCHOR EXPERIENCES"
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                        gap="60px"
                        flexDirection={small ? "":"column"}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            flex={1}
                            maxWidth="100%"
                            gap="20px"
                        >
                            <Box width="100%" maxWidth="160px" overflow="hidden">
                                <img width="100%" src={SobreAncora} />
                            </Box>
                            <Box>
                                <Typography
                                    color="#FFFCB6"
                                    fontFamily="Alata"
                                    fontSize="28px"
                                    fontWeight="400"
                                    mb="20px"
                                >
                                    ÂNCORA
                                </Typography>
                                <Typography
                                    color="white"
                                    fontFamily="Arial"
                                    fontSize="15px"
                                    fontWeight="400"
                                >
                                    Usamos nossa Âncora para “prender” nosso público, no bom sentido. Promovemos momentos únicos e
                                    inesquecíveis buscando sempre a inovação: Inovação para novas experiências que se tornarão
                                    as melhores lembranças.
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            flex={1}
                            maxWidth="100%"
                            gap="20px"
                        >
                            <Box  width="100%" maxWidth="160px" overflow="hidden">
                                <img width="100%" src={SobreA} />
                            </Box>
                            <Box>
                                <Typography
                                    color="#FFFCB6"
                                    fontFamily="Alata"
                                    fontSize="28px"
                                    fontWeight="400"
                                    mb="20px"
                                >
                                    "A"
                                </Typography>
                                <Typography
                                    color="white"
                                    fontFamily="Arial"
                                    fontSize="15px"
                                    fontWeight="400"
                                >
                                    As barras laterais simbolizam o "A", que além de ser a inicial da marca,
                                    também reforçam a sustentação e solidez de nossa Âncora. Dessa forma, nos mantemos sempre firmes em nossas
                                    decisões, ideais e em nossa missão.
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" alignItems="center" maxWidth="100%" gap="20px"  flex={1}>
                            <Box  width="100%" maxWidth="160px" overflow="hidden">
                                <img width="100%" src={SobreCruz} />
                            </Box>
                            <Box>
                                <Typography
                                    color="#FFFCB6"
                                    fontFamily="Alata"
                                    fontSize="28px"
                                    fontWeight="400"
                                    mb="20px"
                                >
                                    CRUZ
                                </Typography>
                                <Typography
                                    color="white"
                                    fontFamily="Arial"
                                    fontSize="15px"
                                    fontWeight="400"
                                >
                                    Nossa conduta e nossos ideais são e sempre serão ligados a valores cristãos.
                                    E a cruz é o elemento em nossa logo em que isso se manifesta de forma transparente.
                                    Claro, sempre com total respeito a toda e qualquer forma de crença.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Sobre