import React, { useState } from 'react'
import { Box, Step, Stepper, StepButton, Button } from '@mui/material'
import BackgroundBlur from "../../assets/Background-Blur.jpg"
import PickSeat from './PickSeat';
import Payment from './Payment';



const Checkout = () => {
    const steps = ['Selecione um assento', 'Comprar ingressos', 'Finalize'];
    const [currentStep, setCurrentStep] = useState(0);

    return (
        <Box width="100%" display="flex" justifyContent="center" flexDirection="column" alignItems="center" gap="30px">
            <Box
                width="100%"
                height="90px"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                    background: `linear-gradient(to right, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${BackgroundBlur})`,
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed",
                }}
            >
            </Box>
            <Box
                width="clamp(0px, 100%, 1000px)"
                display="flex"
                justifyContent="center"
                margin="30px"
                flexDirection="column"
                alignItems="center"
                rowGap="50px"
            >
                <Stepper
                    activeStep={currentStep}
                    alternativeLabel
                    sx={{
                        width: "100%",
                        minWidth: "600px"
                    }}
                >
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepButton>
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
                <Box width="100%" display="flex" minHeight="500px" alignItems="center">
                    {currentStep == 0 &&
                        <Box display="flex" width="100%">
                            <PickSeat />
                        </Box>
                    }
                    {currentStep == 1 &&
                        <Box display="flex" width="100%">
                            <Payment />
                        </Box>
                    }
                </Box>
                <Box width="100%" display="flex" justifyContent="space-between">
                    {currentStep > 0 &&
                        <Button
                            onClick={() => {
                                setCurrentStep(currentStep - 1);
                            }}
                        >
                            Voltar
                        </Button>
                    }
                    <Box display="flex" flex={1} justifyContent="end">
                        <Button
                            onClick={() => {
                                setCurrentStep(currentStep + 1);
                            }}
                        >
                            Próximo
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Checkout