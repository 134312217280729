import { Box, styled, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import BackgroundBlur from "../../assets/Background-Blur.jpg"
import Logo from '../../assets/Logo.png'

const Home = () => {
    const small = useMediaQuery("(min-width:876px)")

    const BannerContainer = styled(Box)(() => ({
        width: "100%",
        height: "640px",
        paddingTop:"90px",
        minHeight:"600px",
        maxHeight: "1400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: `linear-gradient(to right, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${BackgroundBlur})`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",

    }));

    const Banner = styled(Box)(() => ({
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        margin: "30px",
        gap:"20px",
        mt: "40px",
        animation: "0.75s linear fadeIn",
        "@keyframes fadeIn": {
            "0%": {
                opacity: 0
            },
            "100%": {
                opacity: 1
            }
        }
    }));

    const LogoBox = styled(Box)(() => (small ? {
        width: "222px",
        mb: "20px"
    }: {
        width:"37%",
        minWidth: "100px",
        mb: "20px"
    }))

    const TitleBox = styled(Box)(() => (small ? {
        color: "white",
        textAlign: "center",
        fontFamily: "Economica",
        fontSize: "26px",
        letterSpacing: "2.3px",
        lineHeight: "66px",
        fontWeight: "400"
    }: {
        color: "white",
        textAlign: "center",
        fontFamily: "Economica",
        fontSize: "24px",
        letterSpacing: "0.4px",
        lineHeight: "1.5em",
        fontWeight: "400"
    }))

    return (
        <BannerContainer>
            <Banner>
                <LogoBox>
                    <img src={Logo} width="100%"></img>
                </LogoBox>
                <TitleBox>
                    <Typography>
                        PROPORCIONANDO AS MELHORES EXPERIÊNCIAS
                    </Typography>
                </TitleBox>
            </Banner>
        </BannerContainer>
    )
}

export default Home