import React, { useState } from 'react'
import { Box, Typography, Button, Divider, styled, ToggleButtonGroup, ToggleButton } from '@mui/material'
import { Today } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

const Selector = (props) => {
    return (
        <ToggleButton
            sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
                border: "1px solid rgba(0,0,0,0.5) !important",
                width: "88px",
                borderRadius: "4px !important",
                fontFamily: "Roboto"
            }}
            value={props.value}
        >
            {props.children}
        </ToggleButton >
    )
}

const Ticket = (props) => {
    const [ticketNum, setTicketNum] = useState(0);


    return (
        <Box
            display="flex"
            height="96px"
            justifyContent="center"
            margin="20px"
            gap="10px"
            border="1px solid #4742F1"
            borderRadius="5px"
        >
            <Box
                display="flex"
                flex={2}
                flexDirection="column"
                justifyContent="space-between"
                padding="15px"
                fontFamily="Montserrat"
                fontWeight="500"
            >
                {props.children}
            </Box>
            <Box
                position="relative"
                sx={{
                    gridArea: "1 / 2 / 1 / 3",
                    borderRight: "1px dashed #4742F1",
                    backgroundColor: "white",
                    "&::before": {
                        position: "absolute",
                        backgroundColor: "white",
                        left: "-6px",
                        top: "-1px",
                        width: "12px",
                        height: "6px",
                        content: "''",
                        border: "1px solid #4742F1",
                        borderTop: "0px solid",
                        borderEndStartRadius: "12px",
                        borderEndEndRadius: "12px"
                    },
                    "&::after": {
                        position: "absolute",
                        backgroundColor: "white",
                        left: "-6px",
                        bottom: "-1px",
                        width: "12px",
                        height: "6px",
                        content: "''",
                        border: "1px solid #4742F1",
                        borderBottom: "0px solid",
                        borderStartEndRadius: "12px",
                        borderStartStartRadius: "12px"
                    }
                }}
            >
            </Box>
            <Box display="flex" flex={1} padding="15px">
                <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" textAlign="center">
                    <Box
                        backgroundColor="rgb(71, 66, 241, 0.5)"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="25px"
                        width="25px"
                        borderRadius="25px"
                        sx={{
                            "&:hover": {
                                cursor: "pointer"
                            },
                            userSelect: "none"
                        }}
                        onClick={() => { if (ticketNum > 0) { setTicketNum(ticketNum - 1) } }}
                    >
                        <Typography>
                            -
                        </Typography>
                    </Box>
                    <Box flex="1" sx={{
                        userSelect: "none"
                    }}>
                        <Typography>
                            {ticketNum}
                        </Typography>
                    </Box>
                    <Box
                        backgroundColor="rgb(71, 66, 241, 0.5)"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="25px"
                        width="25px"
                        borderRadius="25px"
                        sx={{
                            "&:hover": {
                                cursor: "pointer"
                            },
                            userSelect: "none"
                        }}
                        onClick={() => { setTicketNum(ticketNum + 1) }}
                    >
                        <Typography
                        >
                            +
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const TicketBuyer = (props) => {
    const mockEvent = {
        days: [{
            day: "04/04/2025",
            hours: ["16:00", "17:00", "18:00"],
            tickets: {
                inteira: 200,
                meia: 100
            }
        },
        {
            day: "11/04/2025",
            hours: ["16:00", "17:00", "18:00"],
            tickets: {
                inteira: 200,
                meia: 100
            }
        },
        {
            day: "18/04/2025",
            hours: ["16:00", "17:00", "18:00"],
            tickets: {
                inteira: 200,
                meia: 100
            }
        }],

    };

    const [hour, setHour] = useState("");
    const navigate = useNavigate();

    const handleHour = (e, hour) => {
        setHour(hour);
    }

    return (
        <Box
            display="flex"
            minWidth="550px"
            justifyContent="center"
            position="sticky"
            top="0px"
        >
            <Box
                display="flex"
                backgroundColor="white"
                flexDirection="column"
                width="80%"
                borderRadius="15px"
                overflow="hidden"
                boxShadow="0 8px 16px #0000003d"
                margin="40px 0px"
            >
                <Box display="flex" color="white" justifyContent="center" alignItems="center" backgroundColor="#343338" padding="15px 0" gap="10px">
                    <Today />
                    <Typography fontFamily="Montserrat">Selecione a data e opção</Typography>
                </Box>
                <Box display="flex" justifyContent="center" margin="20px" gap="10px">
                    <ToggleButtonGroup
                        sx={{
                            display: "flex",
                            gap: "20px"
                        }}
                    >
                        {mockEvent.days.map((value, index) => (
                            <Selector>
                                <Typography>
                                    {new Date(value.day).getDate()}
                                </Typography>
                                <Typography></Typography>
                            </Selector>
                        ))
                        }
                    </ToggleButtonGroup>
                </Box>
                <Divider />
                <Box display="flex" justifyContent="center" margin="20px" gap="10px">
                    <ToggleButtonGroup
                        sx={{
                            display: "flex",
                            gap: "20px",
                        }}
                        exclusive
                        value={hour}
                        onChange={handleHour}
                    >
                        {mockEvent.days.map((value, index) => (
                            <Selector>
                                <Typography>
                                    {value.day}
                                </Typography>
                                <Typography></Typography>
                            </Selector>
                        ))}
                    </ToggleButtonGroup>
                </Box>
                <Divider />
                <Box display="flex" flexDirection="column" justifyContent="center" margin="20px" gap="10px">
                    <Ticket>
                        <Typography>Inteira</Typography>
                        <Typography>R$ 200,00</Typography>
                    </Ticket>
                    <Ticket>
                        <Typography>Meia-Entrada</Typography>
                        <Typography>R$ 100,00</Typography>
                    </Ticket>
                </Box>
                <Divider />
                <Box display="flex" justifyContent="center" margin="20px" gap="10px">
                    <Button
                        onClick={() => {
                            navigate('/checkout')
                        }}
                    >
                        <Typography
                            color="white"
                            fontFamily="Arial"
                            fontSize="20px"
                            fontWeight="700"
                        >
                            R$ 200,00 - Continuar
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default TicketBuyer;