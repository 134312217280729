import * as yup from 'yup';

const schema = yup.object().shape({
    name: yup.string()
      .min(3, 'O nome deve ter no mínimo 3 caracteres')
      .required('Nome é um atributo obrigatório'),
    
    email: yup.string()
      .email('Um e-mail válido é necessário')
      .required('O e-mail é um campo obrigatório'),
    
    phone: yup.string()
      .matches(/^\d{10,11}$/, 'O Telefone deve ter 10 (Fixo) ou 11 (Celular) digitos')
      .required('O telefone é um campo obrigatório'),
  
    event: yup.string()
      .oneOf(['Once Upon a Time', 'Não relacionado a evento'], "Selecione um valor válido")
      .required('O campo de evento é obrigatório'),
    
    subject: yup.string()
      .oneOf(['Reembolso', 'Outro'], "Selecione um valor válido")
      .required('O campo de motivo é obrigatório'),
    
    message: yup.string()
      .min(15, 'A mensagem deve ter no mínimo 15 caracteres')
      .required('O campo de mensagem é obrigatório'),
  });
  


export default schema;