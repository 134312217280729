import React, { useState } from 'react'
import { Box, Typography, List, ListItem } from '@mui/material';

const PickSeat = () => {
    const [rows, setRows] = useState(Array.from(
        { length: 10 }, (_, i) => (Array.from({ length: 15 }, (_, j) => ({
            seatNum: j + 1,
            selected: false,
        })))));

    return (
        <Box width="100%">
            <Box display="flex" justifyContent="center">
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" rowGap="10px">
                    {rows.map((row, index) => (
                        <List
                            key={`row-${index}`}
                            sx={{
                                display: "flex",
                                listStyle: "none",
                                padding: "5px",
                                columnGap: "30px",
                            }}>
                            {row.map((seat, index2) => (
                                <ListItem
                                    key={`seat-${index2}`}
                                    sx={{
                                        backgroundColor: seat.selected ? "#19D2AE" : "#1976D2",
                                        display: "flex",
                                        justifyContent: "center",
                                        justifySelf: "center",
                                        alignSelf: "center",
                                        alignItems: "center",
                                        height: "25px",
                                        width: "25px",
                                        borderRadius: "25px",
                                        color:"white",
                                        fontFamily: "Roboto",
                                        padding: 0,
                                        "&:hover": {
                                            cursor: "pointer"
                                        }
                                    }}
                                    onClick={() => {
                                        const copy = [...rows];
                                        copy[index][index2].selected = !seat.selected
                                        setRows(copy);
                                    }}
                                >
                                    <Typography
                                    sx={{
                                        fontSize: "16px"
                                    }}
                                    >
                                        {seat.seatNum}
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                    ))}
                </Box>

            </Box>
        </Box>
    )
}

export default PickSeat