import React from 'react'
import { Box, styled } from '@mui/material'
import BackgroundBlur from "../../assets/Background-Blur.jpg"


const MeiaEntrada = () => {
    const BannerContainer = styled(Box)(() => ({
        width: "100%",
        height: "90px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: `linear-gradient(to right, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${BackgroundBlur})`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",

    }));
    return (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <BannerContainer />
            <Box
                display="flex"
                flexDirection="column"
                width="clamp(0px, 100%, 1250px)"
                justifyContent="center"
                alignItems="center"
                padding="70px"
            >
                <Box>
                    <div class="elementor-element elementor-element-5d26be3 e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="5d26be3" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div class="e-con-inner">
                            <div class="elementor-element elementor-element-ac0ecdd e-con-full e-flex e-con e-parent e-lazyloaded" data-id="ac0ecdd" data-element_type="container">
                                <div class="elementor-element elementor-element-ae123eb elementor-widget elementor-widget-heading" data-id="ae123eb" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">Pol&iacute;ticas de Meia-entrada</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-element elementor-element-e3a3360 e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="e3a3360" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div class="e-con-inner">
                            <div class="elementor-element elementor-element-36d2073 e-con-full e-flex e-con e-parent e-lazyloaded" data-id="36d2073" data-element_type="container">
                                <div class="elementor-element elementor-element-4a4fe53 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile_extra__width-initial elementor-widget elementor-widget-text-editor" data-id="4a4fe53" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <h5><strong>Lei de meia-entrada v&aacute;lida para todo o pa&iacute;s<br /><br /></strong></h5>
                                        <p>De acordo com a Lei Federal 12.852 (Estatuto da Juventude) e 12.933 de 2013, o estudante, jovem de baixa renda e pessoa com defici&ecirc;ncia (e um acompanhante) t&ecirc;m direito ao benef&iacute;cio da meia-entrada, limitada a 40% do total de ingressos dispon&iacute;veis para cada evento, nos eventos expressamente previstos em Lei:</p>
                                        <p>Art. 1&ordm; &Eacute; assegurado aos estudantes o acesso a salas de cinema, cineclubes, teatros, espet&aacute;culos musicais e circenses e eventos educativos, esportivos, de lazer e de entretenimento, em todo o territ&oacute;rio nacional, promovidos por quaisquer entidades e realizados em estabelecimentos p&uacute;blicos ou particulares, mediante pagamento da metade do pre&ccedil;o do ingresso efetivamente cobrado do p&uacute;blico em geral.</p>
                                        <h5><strong>Benefici&aacute;rios<br /><br /></strong></h5>
                                        <h6><strong>I- Estudantes &ndash; altera&ccedil;&atilde;o na documenta&ccedil;&atilde;o</strong></h6>
                                        <p>Apresentar a Carteira de Identifica&ccedil;&atilde;o Estudantil &ndash; CIE &ndash; documento que comprova a condi&ccedil;&atilde;o de estudante regularmente matriculado nos n&iacute;veis e modalidades de educa&ccedil;&atilde;o e ensino previstos no T&iacute;tulo V da Lei n&ordm; 9.394, de 1996, conforme modelo &uacute;nico nacionalmente padronizado, com certifica&ccedil;&atilde;o digital e que dever&aacute; conter: nome completo, data de nascimento do estudante, foto recente, nome da institui&ccedil;&atilde;o de ensino, grau de escolaridade e data de validade, conforme Se&ccedil;&atilde;o I do Decreto 8.537/2015. Ser&aacute; exigida a apresenta&ccedil;&atilde;o da CIE no acesso ao evento. Nos termos do artigo 1-A, da Lei 12.933 de 2013, a CIE pode ser emitida pelo Minist&eacute;rio da Educa&ccedil;&atilde;o, ANPG, UNE, Ubes, bem como pelas entidades estudantis estaduais e municipais, pelos Diret&oacute;rios Centrais dos Estudantes (DCE&acute;s), pelos Centros e Diret&oacute;rios Acad&ecirc;micos e por outras entidades de ensino e associa&ccedil;&otilde;es representativas dos estudantes.&nbsp;<strong>Cabe ressaltar que boleto banc&aacute;rio n&atilde;o &eacute; documenta&ccedil;&atilde;o h&aacute;bil para comprovar a condi&ccedil;&atilde;o de estudante.</strong></p>
                                        <h6><strong>II- Jovens de 15 a 29 anos pertencentes a fam&iacute;lias de baixa renda</strong></h6>
                                        <p>V&aacute;lido a partir de 31 de mar&ccedil;o de 2016 inscritos no Cadastro &Uacute;nico para Programas Sociais do Governo Federal &ndash; Cad&Uacute;nico cuja renda mensal seja de at&eacute; 2 (dois) sal&aacute;rios m&iacute;nimos.</p>
                                        <p>Os jovens de baixa renda ter&atilde;o direito ao benef&iacute;cio da meia-entrada mediante a apresenta&ccedil;&atilde;o, no momento da aquisi&ccedil;&atilde;o do ingresso e na portaria ou na entrada do local de realiza&ccedil;&atilde;o do evento, da Identidade Jovem acompanhada de documento de identifica&ccedil;&atilde;o com foto expedido por &oacute;rg&atilde;o p&uacute;blico e v&aacute;lido em todo o territ&oacute;rio nacional.</p>
                                        <h6><strong>III- Deficiente e seu acompanhante</strong></h6>
                                        <p>(este, se necess&aacute;rio) Mediante apresenta&ccedil;&atilde;o de documento que comprove a condi&ccedil;&atilde;o, de acordo com a Lei Federal 12.933 de 2013. Apenas um acompanhante, por pessoa com defici&ecirc;ncia, ter&aacute; direito ao benef&iacute;cio da meia-entrada.</p>
                                        <p>De acordo com a Lei Federal n&ordm; 10.741/2003 (Estatuto do Idoso), os benefici&aacute;rios mencionados abaixo tem direito a compra de meia-entrada (conforme previsto na lei, este benef&iacute;cio n&atilde;o &eacute; limitado):</p>
                                        <h6><strong>IV- Idosos (com idade igual ou superior a 60 anos)</strong></h6>
                                        <p>De acordo com a Lei Federal n&ordm; 10.741/2003 (Estatuto do Idoso), os idosos t&ecirc;m direito ao benef&iacute;cio da meia-entrada (conforme previsto na Lei, este benef&iacute;cio n&atilde;o &eacute; limitado)</p>
                                        <p>Apresentar documento de identidade oficial com foto que comprove a condi&ccedil;&atilde;o.</p>
                                        <h5><strong>Aten&ccedil;&atilde;o:</strong></h5>
                                        <p>Para Pontos de Venda e Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no ato da compra e no acesso ao evento. Para vendas pela Internet e Telefone &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no acesso ao evento.</p>
                                        <p>Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador dever&aacute; complementar o valor do ingresso adquirido para o valor do ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o ser&aacute; permitido.</p>
                                        <h5><strong>S&atilde;o Paulo<br /><br /></strong></h5>
                                        <ul>
                                            <li><strong>Estudantes, jovens de 15 a 29 anos pertencentes a fam&iacute;lias de baixa renda, idoso e deficiente e seu acompanhante.</strong></li>
                                            <li><strong>Diretor, Coordenador Pedag&oacute;gico, Supervisor e titular do quadro de apoio de escolar Estadual e Municipal&nbsp;</strong>&ndash; Lei Estadual SP 15.298/14 (apresentar documento de identidade oficial com foto e carteira funcional da Secretaria de Educa&ccedil;&atilde;o ou Holerite que comprove a condi&ccedil;&atilde;o).</li>
                                            <li><strong>Professor da rede p&uacute;blica Estadual e Municipal</strong>&nbsp;&ndash; Lei Estadual SP 14.729/2012 (apresentar documento de identidade oficial com foto e carteira funcional da Secretaria de Educa&ccedil;&atilde;o ou Holerite que comprove a condi&ccedil;&atilde;o).</li>
                                        </ul>
                                        <h6><strong>Exclusivamente para o munic&iacute;pio de S&atilde;o Paulo:<br /><br /></strong></h6>
                                        <ul>
                                            <li><strong>Aposentados&nbsp;</strong>&ndash; Lei Municipal SP n&ordm; 12.325/1997 (apresentar documento de identidade oficial com foto e cart&atilde;o de benef&iacute;cio do INSS que comprove a condi&ccedil;&atilde;o).</li>
                                        </ul>
                                        <h5><strong>Aten&ccedil;&atilde;o:<br /></strong></h5>
                                        <p>Para Pontos de Venda e Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no ato da compra e no acesso ao evento. Para vendas pela Internet e Telefone &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no acesso ao evento.</p>
                                        <p>Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador dever&aacute; complementar o valor do ingresso adquirido para o valor do ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o ser&aacute; permitido.</p>
                                        <h5><strong>Rio de Janeiro<br /><br /></strong></h5>
                                        <ul>
                                            <li><strong>Estudantes, jovens de 15 a 29 anos pertencentes a fam&iacute;lias de baixa renda, idoso e deficiente e seu acompanhante.</strong></li>
                                            <li><strong>Menores de 21 anos&nbsp;</strong>&ndash; Lei Estadual RJ n&ordm; 3.364/2000 (apresentar documento de identidade oficial com foto que comprove idade inferior a 21 anos).</li>
                                        </ul>
                                        <h6>Exclusivamente para o munic&iacute;pio Rio de Janeiro:<br /><br /></h6>
                                        <ul>
                                            <li>
                                                <p><strong>Professores e Profissionais da rede p&uacute;blica Municipal de ensino</strong>&nbsp;&ndash; Lei Municipal RJ n&ordm; 3.424/2002 (apresenta&ccedil;&atilde;o da carteira funcional emitida pela Secretaria Municipal de Educa&ccedil;&atilde;o).</p>
                                            </li>
                                        </ul>
                                        <h5><strong>Aten&ccedil;&atilde;o:</strong></h5>
                                        <p>Para Pontos de Venda e Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no ato da compra e no acesso ao evento. Para vendas pela Internet e Telefone &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no acesso ao evento.</p>
                                        <p>Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador dever&aacute; complementar o valor do ingresso adquirido para o valor do ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o ser&aacute; permitido.</p>
                                        <h5><strong>Minas Gerais<br /><br /></strong></h5>
                                        <ul>
                                            <li><strong>Estudantes, jovens de 15 a 29 anos pertencentes a fam&iacute;lias de baixa renda, idoso e deficiente e seu acompanhante.</strong></li>
                                            <li><strong>Menores de 21 anos&nbsp;</strong>&ndash; Lei Municipal de Belo Horizonte n&ordm; 9.070/2005 (apresentar documento de identidade oficial com foto que comprove a condi&ccedil;&atilde;o).</li>
                                        </ul>
                                        <h5><strong>Aten&ccedil;&atilde;o:</strong></h5>
                                        <p>Para Pontos de Venda e Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no ato da compra e no acesso ao evento. Para vendas pela Internet e Telefone &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no acesso ao evento.</p>
                                        <p>Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador dever&aacute; complementar o valor do ingresso adquirido para o valor do ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o ser&aacute; permitido.</p>
                                        <h5><strong>Rio Grande do Sul<br /><br /></strong></h5>
                                        <ul>
                                            <li><strong>Estudantes, jovens de 15 a 29 anos pertencentes a fam&iacute;lias de baixa renda, idoso e deficiente e seu acompanhante.</strong></li>
                                            <li><strong>Doadores regulares de sangue&nbsp;</strong>(registrados no hemocentro e nos bancos de sangue dos hospitais do Estado) &ndash; Lei Estadual RS n&ordm; 13.891/2012 (apresentar documento de identidade oficial com foto e carteira de controle das doa&ccedil;&otilde;es expedida pela Secretaria de Estado da Sa&uacute;de ou pelos hemocentros e bancos de sangue do Estado do Rio Grande do Sul, dentro do prazo de validade).</li>
                                        </ul>
                                        <h6><strong>Exclusivamente para o munic&iacute;pio de Porto Alegre:<br /><br /></strong></h6>
                                        <ul>
                                            <li><strong>Menores de 15 anos</strong>&nbsp;&ndash; Descontos nos termos das leis municipais 9989/2006 e 11.211/2012.</li>
                                            <li><strong>Aposentados ou Pensionistas do INSS&nbsp;</strong>(que recebam at&eacute; tr&ecirc;s sal&aacute;rios m&iacute;nimos) &ndash; Lei Municipal de Porto Alegre n&ordm; 7.366/1993 (apresentar documento fornecido pela Federa&ccedil;&atilde;o dos Aposentados e Pensionistas do Estado do Rio Grande do Sul ou outras Associa&ccedil;&otilde;es de Classe devidamente registradas ou filiadas &agrave; citada Federa&ccedil;&atilde;o. Deve-se comprovar o preenchimento dos requisitos previstos na lei).</li>
                                        </ul>
                                        <h5><strong>Aten&ccedil;&atilde;o:</strong></h5>
                                        <p>Para Pontos de Venda e Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no ato da compra e no acesso ao evento. Para vendas pela Internet e Telefone &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no acesso ao evento.</p>
                                        <p>Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador dever&aacute; complementar o valor do ingresso adquirido para o valor do ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o ser&aacute; permitido.</p>
                                        <h5><strong>Rio Grande do Norte<br /><br /></strong></h5>
                                        <ul>
                                            <li><strong>Estudantes, jovens de 15 a 29 anos pertencentes a fam&iacute;lias de baixa renda, idoso e deficiente e seu acompanhante.</strong></li>
                                        </ul>
                                        <h6><strong>Exclusivamente para o munic&iacute;pio de Natal:<br /><br /></strong></h6>
                                        <ul>
                                            <li><strong>Menores de 7 anos</strong>&nbsp;&ndash; Lei Municipal de Natal n&ordm;6.503/93 e 4.743/96 (apresentar documento de identidade oficial com foto que comprove idade inferior a 7 anos).</li>
                                        </ul>
                                        <h5><strong>Aten&ccedil;&atilde;o:<br /></strong></h5>
                                        <p>Para Pontos de Venda e Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no ato da compra e no acesso ao evento. Para vendas pela Internet e Telefone &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no acesso ao evento.</p>
                                        <p>Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador dever&aacute; complementar o valor do ingresso adquirido para o valor do ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o ser&aacute; permitido.</p>
                                        <h5><strong>Paran&aacute;<br /><br /></strong></h5>
                                        <ul>
                                            <li><strong>Estudantes, jovens de 15 a 29 anos pertencentes a fam&iacute;lias de baixa renda, idoso e deficiente e seu acompanhante.</strong></li>
                                            <li><strong>Doadores regulares de sangue&nbsp;</strong>(registrados no hemocentro e nos bancos de sangue dos hospitais do Estado) &ndash;&nbsp;Lei Estadual PR n&ordm; 13.964/2002 (apresentar documento de identidade oficial com foto e documento oficial expedido pela Secretaria de Estado da Sa&uacute;de que comprove a condi&ccedil;&atilde;o).</li>
                                            <li><strong>Professores da rede p&uacute;blica e particular Estadual&nbsp;</strong>&ndash; Lei Estadual PR n&ordm; 15.876/2008 (apresentar documento de identidade oficial com foto e comprovante de v&iacute;nculo empregat&iacute;cio com a institui&ccedil;&atilde;o de ensino que comprove a condi&ccedil;&atilde;o).</li>
                                        </ul>
                                        <h5><strong>Aten&ccedil;&atilde;o:</strong></h5>
                                        <p>Para Pontos de Venda e Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no ato da compra e no acesso ao evento. Para vendas pela Internet e Telefone &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no acesso ao evento.</p>
                                        <p>Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador dever&aacute; complementar o valor do ingresso adquirido para o valor do ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o ser&aacute; permitido.</p>
                                        <h5><strong>Santa Catarina<br /><br /></strong></h5>
                                        <ul>
                                            <li><strong>Estudantes, jovens de 15 a 29 anos pertencentes a fam&iacute;lias de baixa renda, idoso e deficiente e seu acompanhante.</strong></li>
                                            <li><strong>Menores de 18 anos</strong>&nbsp;&ndash; Lei Estadual SC n&ordm; 12.570/2003 (apresentar documento de identidade oficial com foto que comprove idade inferior a 18 anos).</li>
                                            <li><strong>Doadores de Sangue&nbsp;</strong>&ndash; fundamenta&ccedil;&atilde;o legal &ndash; Lei Estadual 14.132/2007 (documento oficial de doador de sangue, emitido pelos hemocentros e bancos de sangue do Estado de Santa Catarina, devidamente registrados).</li>
                                            <li><strong>Professores de educa&ccedil;&atilde;o infantil, de ensino fundamental, m&eacute;dio e superior das institui&ccedil;&otilde;es de ensino p&uacute;blicas e particulares do Munic&iacute;pio de Florian&oacute;polis e Professores da educa&ccedil;&atilde;o b&aacute;sica (ensino infantil, fundamental e m&eacute;dio) do Estado de Santa Catarina&nbsp;</strong>&ndash; fundamenta&ccedil;&atilde;o legal &ndash; Lei Estadual 16.448/2014 e Lei Municipal 8.019/2009 (comprovante de recebimento salarial atualizado e/ou contracheque que identifique o &oacute;rg&atilde;o e/ou estabelecimento de ensino empregador, funcion&aacute;rio e o cargo que ocupa, al&eacute;m da apresenta&ccedil;&atilde;o do documento de identidade oficial com foto).</li>
                                        </ul>
                                        <h5><strong>Aten&ccedil;&atilde;o:</strong></h5>
                                        <p>Para Pontos de Venda e Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no ato da compra e no acesso ao evento. Para vendas pela Internet e Telefone &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no acesso ao evento.</p>
                                        <p>Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador dever&aacute; complementar o valor do ingresso adquirido para o valor do ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o ser&aacute; permitido.</p>
                                        <h5><strong>Goi&aacute;s<br /><br /></strong></h5>
                                        <ul>
                                            <li><strong>Estudantes, jovens de 15 a 29 anos pertencentes a fam&iacute;lias de baixa renda, idoso e deficiente e seu acompanhante.</strong></li>
                                            <li><strong>Professores da rede p&uacute;blica e Estadual e Municipal de ensino</strong>&nbsp;&ndash;&nbsp;Lei Estadual GO n&ordm; 14.975/2004, 17.396/2011 e 17.575/2012 (apresentar documento de identidade oficial com foto e comprovante de v&iacute;nculo empregat&iacute;cio com a institui&ccedil;&atilde;o de ensino que comprove a condi&ccedil;&atilde;o).</li>
                                        </ul>
                                        <h6>Exclusivamente no munic&iacute;pio de Goi&acirc;nia:<br /><br /></h6>
                                        <ul>
                                            <li><strong>Doadores regulares de sangue</strong>&nbsp;(registrados perante a Secretaria Municipal de Sa&uacute;de/banco de sangue) &ndash;&nbsp;Lei Municipal n&ordm; 8.558/2007 (apresentar documento de identidade oficial com foto e documento oficial expedido pela Secretaria de Estado da Sa&uacute;de/banco de sangue, v&aacute;lido e vigente).</li>
                                        </ul>
                                        <h6><strong>Exclusivamente em Distrito Federal &ndash; Bras&iacute;lia:<br /><br /></strong></h6>
                                        <ul>
                                            <li><strong>Professores do sistema de ensino da rede p&uacute;blica e particular &ndash; em atividade e aposentados</strong>&nbsp;&ndash;&nbsp;Lei Distrital n&ordm; 3.516/2004 (apresentar documento de identidade oficial com foto e holerite &ndash; contracheque &ndash; que comprove a condi&ccedil;&atilde;o).</li>
                                        </ul>
                                        <h5><strong>Aten&ccedil;&atilde;o:</strong></h5>
                                        <p>Para Pontos de Venda e Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no ato da compra e no acesso ao evento. Para vendas pela Internet e Telefone &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no acesso ao evento.</p>
                                        <p>Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador dever&aacute; complementar o valor do ingresso adquirido para o valor do ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o ser&aacute; permitido.</p>
                                        <h5><strong>Bahia<br /><br /></strong></h5>
                                        <ul>
                                            <li><strong>Estudantes, jovens de 15 a 29 anos pertencentes a fam&iacute;lias de baixa renda, idoso e deficiente e seu acompanhante.</strong></li>
                                            <li><strong>Doadores regulares de sangue&nbsp;</strong>(aqueles registrados nos hemocentros e bancos de sangue dos hospitais p&uacute;blicos ou privados do Estado da Bahia) &ndash;&nbsp;Lei Estadual BA n&ordm; 13.183/2014 (apresentar documento que comprove a condi&ccedil;&atilde;o no acesso ao evento e na compra presencial).</li>
                                        </ul>
                                        <h5><strong>Aten&ccedil;&atilde;o:</strong></h5>
                                        <p>Para Pontos de Venda e Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no ato da compra e no acesso ao evento. Para vendas pela Internet e Telefone &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no acesso ao evento.</p>
                                        <p>Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador dever&aacute; complementar o valor do ingresso adquirido para o valor do ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o ser&aacute; permitido.</p>
                                        <h5><strong>Cear&aacute;<br /><br /></strong></h5>
                                        <ul>
                                            <li><strong>Estudantes, jovens de 15 a 29 anos pertencentes a fam&iacute;lias de baixa renda, idoso e deficiente e seu acompanhante.</strong></li>
                                            <li><strong>Professores da rede p&uacute;blica Municipal (apenas para cinemas, teatros e exposi&ccedil;&otilde;es art&iacute;sticas)&nbsp;</strong>&ndash; Lei Municipal de Fortaleza n&ordm; 9.214/2007 (apresentar documento de identidade oficial com foto e carteira expedida pela Secretaria Municipal de Educa&ccedil;&atilde;o e Assist&ecirc;ncia Social &ndash; SEDAS).</li>
                                        </ul>
                                        <h5><strong>Aten&ccedil;&atilde;o:</strong></h5>
                                        <p>Para Pontos de Venda e Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no ato da compra e no acesso ao evento. Para vendas pela Internet e Telefone &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no acesso ao evento.</p>
                                        <p>Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador dever&aacute; complementar o valor do ingresso adquirido para o valor do ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o ser&aacute; permitido.</p>
                                        <h5><strong>Mato Grosso do Sul<br /><br /></strong></h5>
                                        <h6><strong>Exclusivamente para o Munic&iacute;pio de Campo Grande:<br /><br /></strong></h6>
                                        <ul>
                                            <li><strong>Professores da rede Municipal p&uacute;blica e privada de ensino</strong>&nbsp;&ndash; Lei n&ordm; 4341 de 29 de novembro de 2005 (apresentar a carteira funcional emitida pelo respectivo sindicato da categoria).</li>
                                        </ul>
                                        <h5><strong>Aten&ccedil;&atilde;o:</strong></h5>
                                        <p>Para Pontos de Venda e Bilheterias &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no ato da compra e no acesso ao evento. Para vendas pela Internet e Telefone &eacute; necess&aacute;ria a comprova&ccedil;&atilde;o do direito ao benef&iacute;cio da meia-entrada no acesso ao evento.</p>
                                        <p>Caso o benef&iacute;cio n&atilde;o seja comprovado, o portador dever&aacute; complementar o valor do ingresso adquirido para o valor do ingresso integral, caso contr&aacute;rio o acesso ao evento n&atilde;o ser&aacute; permitido.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Box>
        </Box>
    )
}

export default MeiaEntrada