import React from 'react'

import { Box, IconButton, Typography, Button, useMediaQuery, styled } from '@mui/material';
import FooterLogo from "../../assets/Footer_Logo.png"
import ApoiaseLogo from "../../assets/Apoiase-logo.png"
import { Instagram, LinkedIn, WhatsApp } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom';


const Footer = () => {
    const medium = useMediaQuery('(max-width:986px)');
    const small = useMediaQuery('(max-width:687px)');
    const navigate = useNavigate();

    const FirstLevelBox = styled(Box)(() => ({
        backgroundColor: "#343338",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    }))

    const FirstRowFooterContainer = styled(Box)(() => ({
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "start",
        padding: "3% 30px",
        width: "clamp(0px, 100%, 1680px)"
    }));

    const FirstRowFooter = styled(Box)(() => ({
        display: "grid",
        gridTemplateColumns: small ? "1fr" : medium ? "repeat(2,1fr)" : "repeat(5,1fr)",
        gridTemplateRows: small ? "repeat(5, 1fr)" : medium ? "repeat(3,1fr)" : "",
        gap: "4vw"
    }));

    const FooterLogoBox = styled(Box)(() => ({
        gridColumn: small ? "" : medium ? "span 2" : "",
        display: "flex",
        justifyContent: medium ? "center" : "",
        height: "100%",
        padding: "30px 0",
        maxWidth: "100%",
        cursor: "pointer"
    }));

    const QuickLinkBox = styled(Box)(() => ({
        display: "flex",
        flexDirection: "column",
        gap: "10px 10px",
        height: "150px",
        color: "white",
        fontFamily: "Arial",
        fontSize: "16px",
        fontWeight: 700,
        letterSpacing: "1px",
        lineHeight: "25px",
    }));

    const LinksBox = styled(Box)(() => ({
        fontSize: "11px",
        mb: "3px",
        fontWeight: "400"
    }))

    const Link = styled(Typography)(() => ({
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline"
        }
    }))

    const SecondRowFooter = styled(Box)(() => ({
        display: "flex",
        height: "50px",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#2C292C",
        textAlign: "center",
        color: "white",
        fontFamily: "Montserrat",
        fontSize: "14px",
        lineHeight: "1.6em",
        letterSpacing: "-0.45px",
        fontWeight: 300
    }))


    return (
        <FirstLevelBox>
            <FirstRowFooterContainer>
                <FirstRowFooter>
                    <FooterLogoBox onClick={() => navigate("/")}>
                        <img style=
                            {small ?
                                { width: "100%", objectFit: "contain" } :
                                { height: "85px", objectFit: "contain" }}
                            src={FooterLogo} />
                    </FooterLogoBox>
                    <QuickLinkBox>
                        <Typography>
                            Links Rápidos
                        </Typography>
                        <LinksBox>
                            <Link onClick={() => navigate("/")}>Home</Link>
                            <Link onClick={() => navigate("/eventos")}>Eventos</Link>
                            <Link onClick={() => navigate("/sobre")}>Sobre</Link>
                            <Link onClick={() => navigate("/quem-somos")}>Quem Somos</Link>
                            <Link onClick={() => navigate("/fale-conosco")}>Fale Conosco</Link>
                        </LinksBox>
                    </QuickLinkBox>
                    <QuickLinkBox>
                        <Typography>
                            Políticas e termos
                        </Typography>
                        <LinksBox>
                            <Link onClick={() => navigate("/politicas-de-meia-entrada")}>Políticas de meia-entrada</Link>
                            <Link onClick={() => navigate("/politicas-de-privacidade")}>Políticas de privacidade</Link>
                            <Link onClick={() => navigate("/reembolso")}>Políticas de reembolso</Link>
                            <Link onClick={() => navigate("/termos-e-condicoes")}>Termos e condições</Link>
                        </LinksBox>
                    </QuickLinkBox>
                    <QuickLinkBox>
                        <Typography>
                            Seja um Apoiador
                        </Typography>
                        <LinksBox>
                            <Typography>QR Code - Pix</Typography>
                            <IconButton
                                sx={{
                                    mt: "5px",
                                    padding: "0"
                                }}
                                onClick={() => { window.open("https://apoia.se/anchorexperiences1") }}
                            >
                                <img width="100px" src={ApoiaseLogo} />
                            </IconButton>
                        </LinksBox>
                    </QuickLinkBox>
                    <QuickLinkBox>
                        <Button onClick={() => { navigate("/fale-conosco") }}>
                            <Typography>
                                Fale Conosco
                            </Typography>
                        </Button>
                        <Box display="flex" justifyContent="center">
                            <Box width="50px" height="50px" display="flex" justifyContent="center" alignItems="center">
                                <IconButton
                                    margin="0 12.5px"
                                    onClick={() => { window.open("https://www.instagram.com/anchorexperiences/") }}
                                >
                                    <Instagram sx={{
                                        color: 'white',
                                        fontSize: '26px'
                                    }} />
                                </IconButton>
                            </Box>
                            <Box width="50px" height="50px" display="flex" justifyContent="center" alignItems="center">
                                <IconButton
                                    margin="0 12.5px"
                                    onClick={() => { window.open("https://www.linkedin.com/company/anchorexperiences/") }}
                                >
                                    <LinkedIn sx={{
                                        color: 'white',
                                        fontSize: '26px'
                                    }} />
                                </IconButton>
                            </Box>
                            <Box width="50px" height="50px" display="flex" justifyContent="center" alignItems="center">
                                <IconButton
                                    margin="0 12.5px"
                                    onClick={() => { window.open("https://api.whatsapp.com/send/?phone=5511991490485&text=Ol%C3%A1%2C+gostaria+de+mais+informa%C3%A7%C3%B5es+sobre+o+Evento%21&type=phone_number&app_absent=0") }}
                                >
                                    <WhatsApp sx={{
                                        color: 'white',
                                        fontSize: '26px'
                                    }} />
                                </IconButton>
                            </Box>
                        </Box>
                    </QuickLinkBox>
                </FirstRowFooter>
            </FirstRowFooterContainer>
            <SecondRowFooter>
                <Typography>
                    Anchor Experiences © 2024 | All Rights Reserved
                </Typography>
            </SecondRowFooter>
        </FirstLevelBox>
    )
}

export default Footer