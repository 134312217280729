import React from 'react'
import { Box, styled } from '@mui/material'
import BackgroundBlur from "../../assets/Background-Blur.jpg"


const Reembolso = () => {
    const BannerContainer = styled(Box)(() => ({
        width: "100%",
        height: "90px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: `linear-gradient(to right, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${BackgroundBlur})`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",

    }));
    return (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <BannerContainer />
            <Box
                display="flex"
                flexDirection="column"
                width="clamp(0px, 100%, 1250px)"
                justifyContent="center"
                alignItems="center"
                padding="70px"
            >
                <Box>
                    <div class="elementor-element elementor-element-5d26be3 e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="5d26be3" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div class="e-con-inner">
                            <div class="elementor-element elementor-element-ac0ecdd e-con-full e-flex e-con e-parent e-lazyloaded" data-id="ac0ecdd" data-element_type="container">
                                <div class="elementor-element elementor-element-ae123eb elementor-widget elementor-widget-heading" data-id="ae123eb" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">Pol&iacute;ticas de Reembolso</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-element elementor-element-e3a3360 e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="e3a3360" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div class="e-con-inner">
                            <div class="elementor-element elementor-element-36d2073 e-con-full e-flex e-con e-parent e-lazyloaded" data-id="36d2073" data-element_type="container">
                                <div class="elementor-element elementor-element-4a4fe53 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile_extra__width-initial elementor-widget elementor-widget-text-editor" data-id="4a4fe53" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <h5><span class="csh-markdown csh-markdown-list" data-type="1"><span class="csh-markdown csh-markdown-bold csh-font-sans-medium">Prazos para solicita&ccedil;&atilde;o de reembolso</span></span></h5>
                                        <ol>
                                            <li>Em caso de cancelamento de pedidos, o reembolso do valor do ingresso, descontada a taxa de servi&ccedil;o, somente ser&aacute; efetuado caso a solicita&ccedil;&atilde;o seja feita no prazo de at&eacute; 7 (sete) dias a contar da data da compra (como garante o C&oacute;digo de Defesa do Consumidor).&nbsp;</li>
                                        </ol>
                                        <h5>Reembolso em casos espec&iacute;ficos</h5>
                                        <ol>
                                            <li>Caso o cliente solicite o cancelamento, o reembolso apenas ser&aacute; efetuado se esta solicita&ccedil;&atilde;o for feita num prazo m&iacute;nimo de 48 (quarenta e oito) horas de anteced&ecirc;ncia do hor&aacute;rio de in&iacute;cio do evento.</li>
                                            <li>Em caso de cancelamento, adiamento ou altera&ccedil;&atilde;o significativa no evento por parte da produtora, os compradores dos ingressos para o respectivo evento ser&atilde;o notificados, sendo oferecida a possibilidade de atribuir o ingresso a uma nova edi&ccedil;&atilde;o ou a op&ccedil;&atilde;o de reembolso.</li>
                                        </ol>
                                        <h5>Como solicitar o reembolso</h5>
                                        <ol>
                                            <li>Para solicitar o reembolso, entre em contato diretamente conosco, atrav&eacute;s de algum canal do&nbsp;<a href="https://anchorexperiences.com.br/fale-conosco/" target="_blank" rel="noopener">fale conosco</a>, informando o evento que deseja solicitar o ressarcimento.</li>
                                        </ol>
                                        <h5>Processamento do reembolso</h5>
                                        <ol>
                                            <li>A restitui&ccedil;&atilde;o do valor, ao ser cancelado, &eacute; feita de acordo com a forma de compra (cart&atilde;o de cr&eacute;dito, Pix, etc.).</li>
                                            <li>O prazo para o estorno ser efetivado depende da forma de pagamento utilizada e as pol&iacute;ticas do banco ou da operadora do cart&atilde;o de cr&eacute;dito.</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Box>
        </Box>
    )
}

export default Reembolso