import { Box, styled } from '@mui/material'
import BackgroundBlur from "../../assets/Background-Blur.jpg"
import React from 'react'

const Privacidade = () => {
    const BannerContainer = styled(Box)(() => ({
        width: "100%",
        height: "90px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: `linear-gradient(to right, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${BackgroundBlur})`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",

    }));

    return (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <BannerContainer />
            <Box
                display="flex"
                flexDirection="column"
                width="clamp(0px, 100%, 1250px)"
                justifyContent="center"
                alignItems="center"
                padding="70px"
            >
                <Box>
                    <Box>
                        <div class="elementor-element elementor-element-4a5ba2d e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="4a5ba2d" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                            <div class="e-con-inner">
                                <div class="elementor-element elementor-element-2151813 e-con-full e-flex e-con e-child" data-id="2151813" data-element_type="container">
                                    <div class="elementor-element elementor-element-699f400 elementor-widget elementor-widget-heading" data-id="699f400" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <h2 class="elementor-heading-title elementor-size-default">Pol&iacute;ticas de privacidade</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-720d463 e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="720d463" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                            <div class="e-con-inner">
                                <div class="elementor-element elementor-element-9b0c13d e-con-full e-flex e-con e-child" data-id="9b0c13d" data-element_type="container">
                                    <div class="elementor-element elementor-element-194e6de elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile_extra__width-initial elementor-widget elementor-widget-text-editor" data-id="194e6de" data-element_type="widget" data-widget_type="text-editor.default">
                                        <div class="elementor-widget-container">
                                            <p>A sua privacidade &eacute; importante para n&oacute;s. &Eacute; pol&iacute;tica da Anchor Experiences respeitar a sua privacidade em rela&ccedil;&atilde;o a qualquer informa&ccedil;&atilde;o sua que possamos coletar no site&nbsp;<a href="http://anchorexperiences.com.br/">Anchor Experiences</a>, e outros sites que possu&iacute;mos e operamos.</p>
                                            <p>Solicitamos informa&ccedil;&otilde;es pessoais apenas quando realmente precisamos delas para lhe fornecer um servi&ccedil;o. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Tamb&eacute;m informamos por que estamos coletando e como ser&aacute; usado.</p>
                                            <p>Apenas retemos as informa&ccedil;&otilde;es coletadas pelo tempo necess&aacute;rio para fornecer o servi&ccedil;o solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceit&aacute;veis para evitar perdas e roubos, bem como acesso, divulga&ccedil;&atilde;o, c&oacute;pia, uso ou modifica&ccedil;&atilde;o n&atilde;o autorizados.</p>
                                            <p>N&atilde;o compartilhamos informa&ccedil;&otilde;es de identifica&ccedil;&atilde;o pessoal publicamente ou com terceiros, exceto quando exigido por lei.</p>
                                            <p>O nosso site pode ter links para sites externos que n&atilde;o s&atilde;o operados por n&oacute;s (tiqueteiras, mapas, redes sociais, etc.). Esteja ciente de que n&atilde;o temos controle sobre o conte&uacute;do e pr&aacute;ticas desses sites e n&atilde;o podemos aceitar responsabilidade por suas respectivas pol&iacute;ticas de privacidade.</p>
                                            <p>Voc&ecirc; &eacute; livre para recusar a nossa solicita&ccedil;&atilde;o de informa&ccedil;&otilde;es pessoais, entendendo que talvez n&atilde;o possamos fornecer alguns dos servi&ccedil;os desejados.</p>
                                            <p>O uso continuado de nosso site ser&aacute; considerado como aceita&ccedil;&atilde;o de nossas pr&aacute;ticas em torno de privacidade e informa&ccedil;&otilde;es pessoais. Se voc&ecirc; tiver alguma d&uacute;vida sobre como lidamos com dados do usu&aacute;rio e informa&ccedil;&otilde;es pessoais, entre em contacto conosco.</p>
                                            <ul>
                                                <li>O servi&ccedil;o Google AdSense que usamos para veicular publicidade usa um cookie DoubleClick para veicular an&uacute;ncios mais relevantes em toda a Web e limitar o n&uacute;mero de vezes que um determinado an&uacute;ncio &eacute; exibido para voc&ecirc;.</li>
                                                <li>Para mais informa&ccedil;&otilde;es sobre o Google AdSense, consulte as FAQs oficiais sobre privacidade do Google AdSense.</li>
                                                <li>Utilizamos an&uacute;ncios para compensar os custos de funcionamento deste site e fornecer financiamento para futuros desenvolvimentos. Os cookies de publicidade comportamental usados por este site foram projetados para garantir que voc&ecirc; forne&ccedil;a os an&uacute;ncios mais relevantes sempre que poss&iacute;vel, rastreando anonimamente seus interesses e apresentando coisas semelhantes que possam ser do seu interesse.</li>
                                                <li>V&aacute;rios parceiros anunciam em nosso nome e os cookies de rastreamento de afiliados simplesmente nos permitem ver se nossos clientes acessaram o site atrav&eacute;s de um dos sites de nossos parceiros, para que possamos credit&aacute;-los adequadamente e, quando aplic&aacute;vel, permitir que nossos parceiros afiliados ofere&ccedil;am qualquer promo&ccedil;&atilde;o que pode fornec&ecirc;-lo para fazer uma compra.</li>
                                            </ul>
                                            <p><strong>Compromisso do Usu&aacute;rio</strong></p>
                                            <p>O usu&aacute;rio se compromete a fazer uso adequado dos conte&uacute;dos e da informa&ccedil;&atilde;o que a Anchor Experiences oferece no site e com car&aacute;ter enunciativo, mas n&atilde;o limitativo:</p>
                                            <ol>
                                                <li>N&atilde;o se envolver em atividades que sejam ilegais ou contr&aacute;rias &agrave; boa f&eacute; a &agrave; ordem p&uacute;blica;</li>
                                                <li>N&atilde;o difundir propaganda ou conte&uacute;do de natureza racista, xenof&oacute;bica, betano apostas ou azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;</li>
                                                <li>N&atilde;o causar danos aos sistemas f&iacute;sicos (hardwares) e l&oacute;gicos (softwares) do Anchor Experiences, de seus fornecedores ou terceiros, para introduzir ou disseminar v&iacute;rus inform&aacute;ticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.</li>
                                            </ol>
                                            <p><strong>Mais informa&ccedil;&otilde;es</strong></p>
                                            <p>Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que voc&ecirc; n&atilde;o tem certeza se precisa ou n&atilde;o, geralmente &eacute; mais seguro deixar os cookies ativados, caso interaja com um dos recursos que voc&ecirc; usa em nosso site.</p>
                                            <p>Esta pol&iacute;tica &eacute; v&aacute;lida e vigente a partir de 29 de julho de 2024, 23:51.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Privacidade


